
import React, { createContext } from "react";
import useSetState from "../../utils/useSetState";
import FeedbackSnackBar from "../../lib/components/FeedbackSnackBar/FeedbackSnackBar";

export const FeedbackContext = createContext();


const FeedbackProvider = (props) => {

  const [state, setState] = useSetState({
    isActive: false,
    isError: false,
    isLoading: true,
    message: ''
  })

   return (
      <FeedbackContext.Provider
        value={{ feedbackState: state, setFeedbackState: setState }}
      >
        {state.isActive ? 
          <FeedbackSnackBar
            isLoading={state.isLoading}
            isOpen={state.isActive}
            isError={state.isError}
            text={state.message}
            backgroundColor={"white" /*state.isError ? '#D32F2F' : 'green'*/}
            onClose={state.isLoading ? () => {} : toggleFeedback.bind(null, {state, setState})}
          /> 
        : 
          null
        }
          {props.children}
      </FeedbackContext.Provider>
   );
};

export default FeedbackProvider;

const toggleFeedback = ({state, setState}) => {
  setState({isActive: !state.isActive});
}
