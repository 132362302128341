
import React from "react";
import UserContextProvider from "./src/Context/UserContext/UserContext";
import FeedbackProvider from './src/Context/FeedbackContext/FeedbackContext';
import Footer from './src/lib/components/Footer/Footer';
import SessionContainer from './src/Context/SessionContainer/SessionContainer';
import './src/global/globalStyles.css'
import "firebase/auth"
import "firebase/storage"

export const wrapRootElement = ({ element }) => (
  <FeedbackProvider>
    <UserContextProvider>
      <SessionContainer>
        <main>
          { element }
        </main>
      <Footer/>
    </SessionContainer>
    </UserContextProvider>
  </FeedbackProvider>
)
