// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-artist-js": () => import("./../../../src/pages/artist.js" /* webpackChunkName: "component---src-pages-artist-js" */),
  "component---src-pages-dashboard-items-create-js": () => import("./../../../src/pages/dashboard/items/create.js" /* webpackChunkName: "component---src-pages-dashboard-items-create-js" */),
  "component---src-pages-dashboard-items-update-js": () => import("./../../../src/pages/dashboard/items/update.js" /* webpackChunkName: "component---src-pages-dashboard-items-update-js" */),
  "component---src-pages-dashboard-my-decks-create-js": () => import("./../../../src/pages/dashboard/myDecks/create.js" /* webpackChunkName: "component---src-pages-dashboard-my-decks-create-js" */),
  "component---src-pages-dashboard-my-decks-index-js": () => import("./../../../src/pages/dashboard/myDecks/index.js" /* webpackChunkName: "component---src-pages-dashboard-my-decks-index-js" */),
  "component---src-pages-dashboard-my-decks-update-js": () => import("./../../../src/pages/dashboard/myDecks/update.js" /* webpackChunkName: "component---src-pages-dashboard-my-decks-update-js" */),
  "component---src-pages-dashboard-profile-edit-js": () => import("./../../../src/pages/dashboard/profile/edit.js" /* webpackChunkName: "component---src-pages-dashboard-profile-edit-js" */),
  "component---src-pages-dashboard-profile-index-js": () => import("./../../../src/pages/dashboard/profile/index.js" /* webpackChunkName: "component---src-pages-dashboard-profile-index-js" */),
  "component---src-pages-deck-item-js": () => import("./../../../src/pages/deckItem.js" /* webpackChunkName: "component---src-pages-deck-item-js" */),
  "component---src-pages-deck-js": () => import("./../../../src/pages/deck.js" /* webpackChunkName: "component---src-pages-deck-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

