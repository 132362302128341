
import React from "react";
import styles from "./Footer.module.css";


const Footer = () => {
    
    return (
        <div className={styles.footer__container}>
            <div className={styles.footer__storage}>
                Available Storage: 1GB
            </div>
            <div>
                <ExternalLink to='https://www.omai.at'><span className={styles.footerItem}>OMAi.at</span></ExternalLink> 
                | 
                <ExternalLink to='https://www.tagtool.org'><span className={styles.footerItem}>Tagtool.org</span></ExternalLink>
                | 
                <ExternalLink to='https://omai.at/imprint'><span className={styles.footerItem}>Imprint</span></ExternalLink>
                | 
                <ExternalLink to='/'><span className={styles.footerItem}>Terms & conditions</span></ExternalLink>
                {/* Tagtool.org | Imprint | Terms & conditions */}
            </div>
        </div>
    )
  }

  export default Footer;

  const ExternalLink = ({ children, to }) => {
      return (
          <a style={{ textDecoration: 'none' }} target='_blank' href={to}>{ children }</a>
      )
  }

  
  