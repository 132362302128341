
import React, { useContext, useEffect } from "react";
import { UserContext } from "../UserContext/UserContext";
import validate_session from "../../utils/validate_session";


const SessionContainer = ({ children }) => {

  const { userGlobalState, setUserGlobalState } = useContext(UserContext);

  useEffect(() => {
    
    if(!userGlobalState) {
        validate_session().then((data) => {

            if(data.error) {
                setUserGlobalState({ isValid: false })
            }

            else {
                const { artist, user } = data;
                setUserGlobalState({user, artist, isValid: true})
            }
          
        })
        .catch((err) => {

        })
    }

  }, []);

  return children;
   
};

export default SessionContainer;
