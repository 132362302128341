

import axios from 'axios';
import { api_base_url } from '../../global_data';


const validate_session = async () => {

    try {
        const token = localStorage.getItem('tgtxc5');

        if(token) {

            const res = await axios({
                url: api_base_url + '/authTools/validateSession',
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            return res.data;

        }
        
        else {
            return { error: true }
        }

    } catch (err) {
        return { error: true }
    }
    
  }


export default validate_session;